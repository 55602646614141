<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Discount Management</h1>
    </div>
    <b-card no-body>
      <b-tabs pills card v-model="currentStep" v-if="!is_call_api">
        <b-tab active title-link-class="btn btn-light-info">
          <template v-slot:title>
            <!-- <v-icon>mdi-text-box-check</v-icon> -->
            <p class="mb-0">Active Discount</p>
          </template>
          <ListDiscount :is_expired="currentStep"></ListDiscount>
        </b-tab>
        <b-tab title-link-class="btn btn-light-info">
          <template v-slot:title>
            <!-- <v-icon>mdi-bookmark</v-icon> -->
            <p class="mb-0">Expired Discount</p>
          </template>
          <ListDiscount :is_expired="currentStep"></ListDiscount>
        </b-tab>
      </b-tabs>
    </b-card>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "TabListDiscount",
  components: {
    Loading: () => import("vue-loading-overlay"),
    ListDiscount: () => import("./ListDiscount.vue"),
  },
  data() {
    return {
      is_call_api: false,
      currentStep: 0,
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="css" scoped>
.nav.nav-pills .nav-link.active {
  color: #ffffff;
  background-color: #6993ff !important;
}
.text-tab {
  color: #ffffff;
  background-color: #6993ff !important;
}
</style>
